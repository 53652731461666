<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <!-- <h2 class="brand-text text-primary ml-1">
            HoglaBest
          </h2> -->
        </b-link>

        <b-card-title class="mb-1">
          Панель управления
        </b-card-title>
        <b-card-text class="mb-2">
          Пожалуйста, войдите в свою учетную запись
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginValidation"
          @submit.prevent
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="login"
              label="Логин"
            >
              <validation-provider
                #default="{ errors }"
                name="Логин"
                rules="required"
              >
                <b-form-input
                  id="login"
                  v-model="username"
                  name="login"
                  :state="errors.length > 0 ? false:null"
                  :disabled="loading"
                  placeholder="Логин или Email"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Пароль</label>
                <b-link
                  :to="{name:'forgot-password'}"
                  tabindex="3"
                >
                  <small>Войти по коду</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Пароль"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :disabled="loading"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="············"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="loading"
              @click="validationForm"
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-spinner
                  v-if="loading"
                  small
                  class="mr-50"
                />
                <span>Войти</span>
              </div>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard,
  BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend,
  BSpinner,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      username: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.loading = true
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          useJwt.login({
            username: this.username,
            password: this.password,
            role: 'user',
          }).then(response => {
            const { data } = response.data
            const { userData } = data

            useJwt.setToken(data.accessToken)
            useJwt.setRefreshToken(data.refreshToken)
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$store.commit('app/SET_USER', userData)
            this.$store.commit('app/SET_LOGGED_IN', true)
            this.loading = false

            // window.location.replace('/')

            if (userData.tmp) {
              window.location.replace(`/${userData.tmp}`)
            } else {
              window.location.replace('/')
            }
          }).catch(error => {
            this.$refs.loginValidation.setErrors(error)
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
